import React from 'react';
import styled from 'styled-components';
import ScrollParallax from '../../../common/scroll-paralax';
import downtown from '../../../../images/downtown.svg';

const StyledDowntown = styled.div`
	max-width: 64rem;
	margin: -6rem auto 2rem auto;
	@media ${(p) => p.theme.device.mobile} {
		margin: -2rem auto 2rem auto;
	}
	text-align: left;
	position: relative;
	img {
		width: 118%;
		max-width: 118%;
		@media ${(p) => p.theme.device.mobile} {
			width: 160%;
			max-width: 160%;
		}
	}
`;

export default () => (
	<StyledDowntown>
		<ScrollParallax imgSrc={downtown} imgAlt="downtown" min={14} max={-18} />
	</StyledDowntown>
);
