import React from 'react';
import styled from 'styled-components';

const DetailSection = styled.section`
	background-color: ${(p) => p.theme.color.ghost};
	.inner {
		padding: 4rem 1rem;
		margin: 0 auto;
		max-width: 64rem;
		h3 {
			color: ${(p) => p.theme.color.secondary};
			font-size: 1.5rem;
			margin-bottom: 1rem;
			font-family: ${(p) => p.theme.font.display};
		}
		p {
			font-size: 2rem;
			@media ${(p) => p.theme.device.mobile} {
				font-size: 1.25rem;
			}
		}
		.row {
			display: flex;
			@media ${(p) => p.theme.device.mobile} {
				flex-direction: column;
			}
		}
		.col {
			flex: 1;
			&:first-child {
				flex-basis: 33%;
				@media ${(p) => p.theme.device.mobile} {
					flex-basis: 100%;
					margin-bottom: 2rem;
				}
			}
			&:last-child {
				flex-basis: 67%;
				@media ${(p) => p.theme.device.mobile} {
					flex-basis: 100%;
				}
			}
		}
	}
`;

export default () => (
	<DetailSection>
		<div className="inner">
			<div className="row">
				<div className="col">
					<h3>SPEAK TO A HUMAN:</h3>
					<p>
						hello@5fold.ca
						<br />
						(705) 986-0511
					</p>
				</div>
				<div className="col">
					<h3>STOP BY:</h3>
					<p>
						39 Dunlop Street East, Unit 5F
						<br />
						Barrie, ON. L4M 1A2
					</p>
				</div>
			</div>
		</div>
	</DetailSection>
);
