import React from 'react';

import Page from '../components/core/page';
import Opener from '../components/common/opener';
import Form from '../components/pages/contact/form';
import Details from '../components/pages/contact/details';
import Closer from '../components/common/closer';
import Downtown from '../components/pages/contact/downtown';

const ContactPage = () => {
	return (
		<Page
			title="Contact"
			desc="Don't be a stranger, we're here to help! Phone, email, online form, carrier pigeon, we answer them all."
		>
			<Opener title="contact" subtitle="DON'T BE A STRANGER." />
			<Downtown />
			<Form />
			<Details />
			<Closer>
				<h2>
					If our work doesn't get you compliments from at least five of your
					friends, <strong>we'll find you five new friends.</strong>
				</h2>
			</Closer>
		</Page>
	);
};

export default ContactPage;
