import React from 'react';
import { Parallax } from 'react-scroll-parallax';

const Image = ({ src, alt }) => {
	/*
	const { parallaxController } = useController();
	const handleLoad = () => {
		parallaxController.update();
	};
	*/
	return <img src={src} alt={alt} />;
};

export default ({ min, max, imgSrc, imgAlt }) => (
	<Parallax x={[min, max]}>
		<Image src={imgSrc} alt={imgAlt} />
	</Parallax>
);
