import React, { useState } from 'react';
import styled from 'styled-components';

const FormSection = styled.section`
	background-color: ${(p) => p.theme.color.primary};
	.inner {
		padding: 4rem 1rem;
		margin: 0 auto;
		max-width: 64rem;
		h3 {
			color: ${(p) => p.theme.color.secondary};
			font-size: 1.5rem;
			margin-bottom: 2rem;
			font-family: ${(p) => p.theme.font.display};

			&.submitted {
				text-align: center;
			}
		}
		input {
			width: 100%;
			border-width: 0;
			border-bottom: 1px solid ${(p) => p.theme.color.dark};
			border-radius: 0;
			background-color: transparent;
			height: 2rem;
			margin-bottom: 0.25rem;
			font-family: ${(p) => p.theme.font.body};
			font-size: 1rem;
			&::placeholder {
				color: ${(p) => p.theme.color.grey};
			}
		}
		form {
			text-align: center;
		}
		.row {
			display: flex;
			margin-bottom: 2rem;
			@media ${(p) => p.theme.device.mobile} {
				flex-direction: column;
			}
		}
		.col {
			flex: 1;
			margin-right: 2rem;
			@media ${(p) => p.theme.device.mobile} {
				margin-right: 0;
			}
			&:last-child {
				margin-right: 0;
			}
			text-align: left;
		}
		.left {
			text-align: left;
		}
		label {
			text-align: left;
			font-family: ${(p) => p.theme.font.display};
			margin-bottom: 0.25rem;
			display: block;
		}
		textarea {
			width: 100%;
			border: 1px solid ${(p) => p.theme.color.dark};
			border-radius: 0;
			resize: none;
			background-color: transparent;
			margin-bottom: 2rem;
			font-family: ${(p) => p.theme.font.body};
			font-size: 1rem;
			padding: 1rem;
			&::placeholder {
				color: ${(p) => p.theme.color.grey};
			}
		}
		button {
			border: 2px solid ${(p) => p.theme.color.secondary};
			color: ${(p) => p.theme.color.secondary};
			font-family: ${(p) => p.theme.font.display};
			font-size: 1.25rem;
			border-radius: 1rem;
			padding: 0.25rem 2rem 0;
			cursor: pointer;
			transition: all ease 300ms;
			background-color: ${(p) => p.theme.color.primary};
			&:hover {
				color: ${(p) => p.theme.color.lightest};
				background-color: ${(p) => p.theme.color.secondary};
			}
		}
	}
`;

function encode(data) {
	return Object.keys(data)
		.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
		.join('&');
}

export default () => {
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [inputs, setInputs] = useState({});

	const handleChange = (e) => {
		setInputs({ ...inputs, [e.target.name]: e.target.value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const form = e.target;
		const formName = form.getAttribute('name');

		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({
				'form-name': formName,
				...inputs,
			}),
		})
			.then(() => {
				window.dataLayer.push({ event: 'formSubmitted', formName: formName }); // Push form submission to Google Tag Manager dataLayer
				setIsSubmitted(true);
			})
			.catch((error) => alert(error));
	};

	return (
		<FormSection>
			<div className="inner">
				<h3>QUICK INQUIRY</h3>
				{!!isSubmitted ? (
					<h3 className="submitted">
						Thanks very much for reaching out. <br />
						We will be in contact with you shortly.
					</h3>
				) : (
					<form
						method="POST"
						action="/thanks"
						name="Contact"
						data-netlify-honeypot="bot-field"
						data-netlify="true"
						onSubmit={(e) => handleSubmit(e)}
					>
						<div className="row">
							<div className="col">
								<input
									type="text"
									placeholder="John Smith"
									name="Name"
									id="name"
									onChange={(e) => handleChange(e)}
									value={inputs.name}
									required
								/>
								<label htmlFor="name">NAME*</label>
							</div>
							<div className="col">
								<input
									type="email"
									placeholder="jsmith@email.com"
									name="Email"
									id="email"
									onChange={(e) => handleChange(e)}
									value={inputs.email}
									required
								/>
								<label htmlFor="email">EMAIL*</label>
							</div>
							<div className="col">
								<input
									type="tel"
									placeholder="(555) 555-5555"
									name="Phone"
									id="phone"
									onChange={(e) => handleChange(e)}
									value={inputs.phone}
								/>
								<label htmlFor="phone">PHONE</label>
							</div>
						</div>
						<div className="left">
							<label htmlFor="message">MESSAGE</label>
						</div>
						<textarea
							name="Message"
							id="message"
							rows="12"
							placeholder="Hi there..."
							onChange={(e) => handleChange(e)}
							value={inputs.message}
						/>
						{/* The `form-name` hidden field is required to support form submissions without JavaScript */}
						<input type="hidden" name="form-name" value="Contact Page" />
						<div hidden>
							<label>
								Don’t fill this out:{' '}
								<input name="bot-field" onChange={(e) => handleChange(e)} />
							</label>
						</div>
						<button>SUBMIT</button>
					</form>
				)}
			</div>
		</FormSection>
	);
};
